import axios from "axios";
import * as types from "./ActionTypes";
export const dispatchcreateOrder =
  (payload, toast, token) => async (dispatch) => {
    dispatch({ type: types.CREATE_ORDER_REQUEST });
    try {
      const temp = payload;
      const imageForm = new FormData();
      const alldescription = temp.orderDescription.map((ele, i) => {
        const imageArray = payload.orderDescription[i].getAll("myFile");
        imageArray.forEach((e) => {
          imageForm.append("myFile", e);
        });
        for (const entry of ele.entries()) {
          const [name, value] = entry;
          if (name === "myFile") {
          } else {
            const title = ele.get("title");
            const description = ele.get("description");
            const image = ele.getAll("imagename");
            return { title, description, image };
          }
        }
      });
      imageForm.append("orderDescription", JSON.stringify(alldescription));
      imageForm.append("orderData", JSON.stringify(payload.orderData));
      imageForm.append("dates", JSON.stringify(payload.dates));
      imageForm.append("orderID", payload.orderID);
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/sheet/createorder`,
        imageForm,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({ type: types.CREATE_ORDER_SUCCESS, payload: res.data.data });
      toast({
        title: res.data.msg,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (err) {
      dispatch({ type: types.CREATE_ORDER_FAILURE });
      console.log(err);
      toast({
        title: "Error",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
export const fetchProduction = (params) => async (dispatch) => {
  try {
    dispatch({ type: types.FETCH_ORDER_REQUEST });
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/sheet/fetchorder?year=${
        params.get("year") == null
          ? new Date().getFullYear()
          : params.get("year")
      }&month=${params.get("month")}`
    );
    res.data.data = res.data.data.map((ele) => {
      ele.checked = false;
      ele.description = ele?.description?.map((e, i) => {
        e.position = i;
        return e;
      });
      return ele;
    });
    dispatch({ type: types.FETCH_ORDER_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({ type: types.FETCH_ORDER_FAILURE });
    console.log(error);
  }
};
export const deleteProduction = (payload, token, data) => async (dispatch) => {
  try {
    dispatch({ type: types.DELETE_ORDER_REQUEST });
    const res = await axios.delete(
      `${process.env.REACT_APP_BACKEND_URL}/sheet/deleteorder/${payload.join(
        "-"
      )}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const filterData = data.filter((ele) => !payload.includes(ele.id));
    dispatch({ type: types.DELETE_ORDER_SUCCESS, payload: filterData });
  } catch (error) {
    dispatch({ type: types.DELETE_ORDER_FAILURE });
    console.log(error);
  }
};
export const updateOrderDetails =
  (payload, id, token, stage, wp_payload) => async (dispatch) => {
    try {
      dispatch({ type: types.UPDATE_ORDER_REQUEST });
      const res = await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/sheet/updateorder/${id}?stage=${stage}`,
        {
          orderdescription: payload,
          reason: wp_payload?.reason,
          awb: wp_payload?.awb,
          courier: wp_payload?.courier,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({ type: types.UPDATE_ORDER_SUCCESS, payload: res.data.data });
    } catch (error) {
      console.log(error);
    }
  };
export const deleteProduct = (position, id, token) => async (dispatch) => {
  try {
    dispatch({ type: types.UPDATE_ORDER_REQUEST });
    const res = await axios.delete(
      `${process.env.REACT_APP_BACKEND_URL}/sheet/deleteproduct?id=${id}&pos=${position}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: types.UPDATE_ORDER_SUCCESS, payload: res.data.data });
  } catch (error) {
    console.log(error);
  }
};
export const updateCheckBox = (id) => async (dispatch) => {
  try {
    dispatch({ type: types.UPDATE_CHECK_REQUEST });
    dispatch({ type: types.UPDATE_CHECK_SUCCESS, payload: id });
  } catch (error) {
    console.log(error);
  }
};
export const insertProduct =
  (payload, id, token, toast) => async (dispatch) => {
    dispatch({ type: types.CREATE_ORDER_REQUEST });
    try {
      const res = await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/sheet/insertproduct/${id}`,
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({ type: types.CREATE_ORDER_SUCCESS, payload: res.data.data });
      toast({
        title: "Product Added",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (err) {
      dispatch({ type: types.CREATE_ORDER_FAILURE });
      console.log(err);
      toast({
        title: "Error",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
export const editProduct = (payload) => async (dispatch) => {
  dispatch({ type: types.UPDATE_EDIT_SUCCESS, payload: payload });
};
export const shipByPartyProduct = (payload) => async (dispatch) => {
  dispatch({ type: types.UPDATE_SELF_PICKUP_SUCCESS, payload: payload });
};
export const updateProductDetails =
  (payload, id, token) => async (dispatch) => {
    try {
      axios
        .patch(
          `${process.env.REACT_APP_BACKEND_URL}/sheet/updateproduct/${id}`,
          payload,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          dispatch({ type: types.UPDATE_EDIT_SUCCESS, payload: {} });
        });
    } catch (error) {
      console.log(error);
    }
  };
export const holdUpdate = (payload) => (dispatch) => {
  dispatch({ type: types.UPDATE_HOLD_SUCCESS, payload: payload });
};
export const getAllData = (data, filter) => (dispatch) => {
  try {
    if (filter == "both" || filter == "distributor") {
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/alldistributor`)
        .then((res) => {
          data.Dealer = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (filter == "both" || filter == "employee") {
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/getallemployee`)
        .then((res) => {
          data.Employee = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    }
    dispatch({ type: types.GET_USER_DATA_SUCCESS, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const tabUpdate = (payload) => (dispatch) => {
  dispatch({ type: types.DELETE_ORDER_REQUEST });
  dispatch({ type: types.TAB_NUMBER_SUCCESS, payload: payload });
  dispatch({ type: types.DELETE_ORDER_FAILURE });
};
const getCurrentDateTime = (display) => {
  let now = new Date();

  // Get date components
  let day = String(now.getDate()).padStart(2, "0");
  let month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero based
  let year = String(now.getFullYear()).slice(-2);
  let fullyear = String(now.getFullYear());

  // Get time components
  let hour = now.getHours();
  let minute = String(now.getMinutes()).padStart(2, "0");
  let second = String(now.getSeconds()).padStart(2, "0");

  // Determine AM/PM
  let meridiem = hour >= 12 ? "PM" : "AM";

  // Convert hour from 24-hour to 12-hour format
  hour = hour % 12;
  hour = hour ? hour : 12; // Handle midnight (0 hours)

  // Construct the formatted date/time string
  let formattedDateTime;
  if (display) {
    formattedDateTime = `${day}/${month}/${fullyear}, ${hour}:${minute}:${second} ${meridiem.toLocaleLowerCase()}`;
  } else {
    formattedDateTime = `${day}-${month}-${year}_${hour}-${minute}-${second}_${meridiem}`;
  }
  return formattedDateTime;
};
export const getReport = (payload, token, pdf, toast) => (dispatch) => {
  try {
    if (pdf) {
      dispatch({ type: types.GENERATE_REPORT_REQUEST });
    } else {
      dispatch({ type: types.UPDATE_ORDER_REQUEST });
    }
    const time = getCurrentDateTime(true);
    payload.time = time;
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/generatereport?pdf=${
          pdf ? "true" : "false"
        }`,
        payload,
        {
          responseType: pdf ? "arraybuffer" : "",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(async (res) => {
        if (pdf == false) {
          dispatch({ type: types.GET_GENERATED_TIME, payload: time });
          dispatch({ type: types.GET_REPORT_SUCCESS, payload: res.data });
        } else {
          const blob = new Blob([res.data], { type: "application/pdf" });
          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = `Report_${getCurrentDateTime(false)}.pdf`;
          a.click();
          URL.revokeObjectURL(url);
          dispatch({ type: types.GET_GENERATED_TIME, payload: time });
        }
        if (pdf) {
          dispatch({ type: types.GENERATE_REPORT_SUCCESS });
          toast({
            title: "PDF Generated",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        } else {
          dispatch({ type: types.UPDATE_ORDER_SUCCESS });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: types.GENERATE_REPORT_FAILURE });
        toast({
          title: "Error in generating PDF",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  } catch (error) {
    console.log(error);
  }
};

export const fetchRawReport = (payload, token) => (dispatch) => {
  try {
    dispatch({ type: types.RAW_METERIAL_REPORT_REQUEST });
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/purchase/rawreport`,
        payload,
        {
          responseType: payload.report ? "arraybuffer" : "json",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (payload.report == false) {
          dispatch({
            type: types.RAW_METERIAL_REPORT_SUCCESS,
            payload: res.data,
          });
        } else {
          const blob = new Blob([res.data], { type: "application/pdf" });
          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = `Raw_Report_${getCurrentDateTime(false)}.pdf`;
          a.click();
          URL.revokeObjectURL(url);
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: types.RAW_METERIAL_REPORT_FAILURE });
      });
  } catch (error) {
    console.log(error);
    dispatch({ type: types.RAW_METERIAL_REPORT_FAILURE });
  }
};

export const generatedTime = () => (dispatch) => {
  const time = getCurrentDateTime(true);

  dispatch({ type: types.GET_GENERATED_TIME, payload: time });
};

export const filterYear = (payload) => (dispatch) => {
  dispatch({ type: types.FILTER_YEAR_SUCCESS, payload: payload });
};

export const loaderUpdate = (payload) => (dispatch) => {
  dispatch({ type: types.LOADER_UPDATE, payload: payload });
};
export const searchProduct = (payload, token) => async (dispatch) => {
  try {
    dispatch({ type: types.SEARCH_FILTER_REQUEST });
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/sheet/searchproduct?search=${payload}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    res.data.data = res.data.data.map((ele) => {
      ele.checked = false;
      return ele;
    });
    dispatch({ type: types.SEARCH_FILTER_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({ type: types.SEARCH_FILTER_FAILURE });
    console.log(error);
  }
};

export const updateNotification = (payload) => (dispatch) => {
  try {
    dispatch({ type: types.UPDATE_NOTIFICATION, payload: payload });
  } catch (error) {
    console.log(error);
  }
};

export const getClientFeedback = (token) => (dispatch) => {
  try {
    dispatch({ type: types.CLIENT_FEEDBACK_REQUEST });
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/feedback`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch({
          type: types.CLIENT_FEEDBACK_SUCCESS,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: types.CLIENT_FEEDBACK_FAILURE });
      });
  } catch (error) {
    console.log(error);
  }
};

export const postCouponDetails = (payload, token) => (dispatch) => {
  try {
    dispatch({ type: types.CREATE_COUPON_REQUEST });
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/coupon/create`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch({
          type: types.CREATE_COUPON_SUCCESS,
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: types.CREATE_COUPON_FAILURE });
      });
  } catch (error) {
    console.log(error);
  }
};

export const rawMeterialAlert = (token) => (dispatch) => {
  try {
    dispatch({ type: types.RAW_METERIAL_ALERT_REQUEST });
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/product/alert`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch({
          type: types.RAW_METERIAL_ALERT_SUCCESS,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({ type: types.RAW_METERIAL_ALERT_FAILURE });
        console.log(err);
      });
  } catch (error) {
    console.log(error);
  }
};

export const addOrUpdateInvoice = (payload, token, toast) => (dispatch) => {
  try {
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/invoice/create`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        toast({
          title: "Invoice IDs Added/Updated",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch(() => {
        toast({
          title: "Invoice did not updated",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  } catch (error) {
    console.log(error);
  }
};

export const addToNotification = (payload) => (dispatch) => {
  dispatch({ type: types.ADD_NOTIFICATION, payload });
};

export const updateLastNotificationID = (payload) => (dispatch) => {
  dispatch({ type: types.LAST_NOTIFICATION_ID, payload });
};

export const paymentStateUpdate = (payload) => (dispatch) => {
  dispatch({ type: types.PAYMENT_UPDATE, payload });
};

export const couponDataUpdate = (couponData, index, value) => (dispatch) => {
  if (index < 0 || index >= couponData.length) {
    console.error("Invalid index");
    return;
  }
  couponData[index].text = value;
  if (index == 5) {
    couponData[index].y = value.split("")?.length > 27 ? 50 : 75;
  }
  dispatch({ type: types.COUPON_DATA_UPDATE, payload: couponData });
};
